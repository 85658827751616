// import {WebSocketLink} from 'apollo-link-ws';
// import {getMainDefinition} from 'apollo-utilities';
import {InMemoryCache} from 'apollo-cache-inmemory';
import {ApolloClient} from 'apollo-client';
import {ApolloLink, from} from 'apollo-link';
import {HttpLink} from 'apollo-link-http';
import {AuthService, AUTH_TOKEN} from '../services/auth-service/auth.service';
import {app} from '../App';

export const HTTP_URI = 'app.servers.http.uri';
export const WEBSOCKET_URI = 'app.servers.websocket.uri';
export const WEBSOCKET_OPTIONS = 'app.servers.websocket.options';
export const GRAPHQL_SERVER = 'app.servers.graphql';

const getAuthToken = () => {
  //const authService = app.getSync('services.AuthService') as AuthService;
  const authService = AuthService.getInstance();
  const _token = authService.authTokenDS.get(AUTH_TOKEN);
  console.log('token =>', _token);
  if (_token) {
    return _token.value;
  }
  return null;
};

export class GraphqlServer {
  readonly httpLink: any;
  readonly wsLink: any;
  link: any;
  splitLink: any;
  readonly authMiddleware: any;
  readonly client: any;
  constructor(public httpURI: any, public token: string | null = null) {
    if (this.httpURI) {
      this.httpLink = new HttpLink({
        uri: this.httpURI,
      });
    }

    this.authMiddleware = new ApolloLink((operation, forward) => {
      // add the authorization to the headers
      const token = getAuthToken();
      operation.setContext({
        headers: {
          // authorization: token || null,
          authentication: token || null,
        },
      });

      return forward(operation);
    });
    this.link = from([this.authMiddleware, this.httpLink]);

    this.client = new ApolloClient({
      link: this.link,
      cache: new InMemoryCache(),
    });
  }
}
