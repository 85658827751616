//@ts-nocheck

import axios from 'axios';

export class HttpServer {
  readonly get = axios.get;
  readonly put = axios.put;
  readonly post = axios.post;
  readonly delete = axios.delete;

  constructor(protected httpTimeout: number = 10000) {
    axios.interceptors.response.use(null, error => {
      const expectedError =
        error.response &&
        error.response.status >= 400 &&
        error.response.status <= 500;

      if (!expectedError) {
        console.log(`http service error : ${error}`);
      }
      return Promise.reject(error);
    });

    axios.defaults.timeout = this.httpTimeout;
  }

  setHeader(key: string, value: string) {
    axios.defaults.headers.common[key] = value;
  }
}
