import {useState, useEffect} from 'react';
import {AuthService} from './auth.service';
import {app} from '../../App';

export const useAuth = () => {
  const authService = AuthService.getInstance();

  return {
    signIn: authService.signIn.bind(authService),
    signOut: authService.signOut.bind(authService),
    isAuthenticated: authService.isAuthenticated.bind(authService),
    getUserProfile: authService.getUserProfile.bind(authService),
    setUserProfile: authService.setUserProfile.bind(authService),
    getAuthToken: authService.getAuthToken.bind(authService),
    getGraphqlURL: authService.getGraphqlURL.bind(authService),
    setGraphqlURL: authService.setGraphqlURL.bind(authService),
    setAuthToken: authService.setAuthToken.bind(authService),
    getActor: authService.getActor.bind(authService),
    setActor: authService.setActor.bind(authService),
    removeUserProfile: authService.removeUserProfile.bind(authService),
    removeActor: authService.removeActor.bind(authService),
    removeAuthToken: authService.removeAuthToken.bind(authService),
    removeGraphqlURL: authService.removeGraphqlURL.bind(authService)
  };
};
